<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">ACCESS MODULE ITEM</h3>
      <div>
        <el-select v-model="sam_id" placeholder="Select Access Module" filterable @change="getAccessModuleItem">
          <el-option v-for="access_module_item in access_module_items" :key="access_module_item.id" :value="access_module_item.id" :label="access_module_item.name"></el-option>
        </el-select>
        <el-button v-if="sam_id" type="primary" style="margin-left: 1rem;" @click="dialogAccessModuleItem = true">Add New Access Module Item</el-button>
      </div>
    </div>
    <el-dialog title="Add Access Module Item" :visible.sync="dialogAccessModuleItem">
      <el-form ref="accessmoduleitemForm" :model="accessmoduleitemForm" label-width="150px">
        <el-form-item label="Access Module">
          <el-select v-model="accessmoduleitemForm.sam_id" placeholder="Select Access Module" filterable @change="getAccessModuleId">
            <el-option v-for="access_module_item in access_module_items" :key="access_module_item.id" :value="access_module_item.id" :label="access_module_item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Access Module Item Name">
          <el-input v-model="accessmoduleitemForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAccessModuleItem = false">Cancel</el-button>
        <el-button type="primary" @click="addAccessModule">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Access Module Item" :visible.sync="editAccessModuleItemDialog">
      <el-form ref="editaccessmoduleitemForm" :model="editaccessmoduleitemForm" label-width="150px">
        <el-form-item label="Access Module">
          <el-select v-model="editaccessmoduleitemForm.sam_id" placeholder="Select Access Module" filterable @change="getAccessModuleId">
            <el-option v-for="access_module_item in access_module_items" :key="access_module_item.id" :value="access_module_item.id" :label="access_module_item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Access Module Item Name">
          <el-input v-model="editaccessmoduleitemForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAccessModuleItemDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateAccessModuleItem">Confirm</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border max-height="580">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="NAME" prop="name"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
        <template slot-scope="props">
          <el-button-group>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editAccessModuleItem(props.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'AccessModuleItem',
  data () {
    return {
      data: [],
      sam_id: null,
      access_module_items: [],
      access_module: [],
      dialogAccessModuleItem: false,
      editAccessModuleItemDialog: false,
      accessmoduleitemForm: {
        name: null,
        sam_id: null,
        vue_parent_name: null,
        vue_object_name: null,
        description: 'NULL'
      },
      editaccessmoduleitemForm: {
        id: null,
        name: null,
        sam_id: null,
        vue_parent_name: null,
        vue_object_name: null,
        description: 'NULL'
      }
    }
  },
  mounted () {
    this.getAccessModule()
  },
  methods: {
    getAccessModule () {
      this.$http
        .get('sysadmin.systemaccess/module-list')
        .then(res => {
          this.access_module_items = res.data.StatusCode ? res.data.body : []
        })
    },
    getAccessModuleId () {
      this.$http
        .get(`sysadmin.systemaccess/module-list/${this.sam_id}`)
        .then(res => {
          this.access_module = res.data.StatusCode ? res.data.body : []
        })
    },
    getAccessModuleItem () {
      this.$http
        .get(`sysadmin.systemaccess/moduleitem-list/${this.sam_id}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body : []
        })
    },
    addAccessModule () {
      this.dialogAccessModuleItem = false
      this.accessmoduleitemForm.sam_id = this.sam_id
      this.accessmoduleitemForm.vue_object_name = this.accessmoduleitemForm.name
      this.accessmoduleitemForm.vue_parent_name = this.access_module.name
      this.$http
        .post('sysadmin.systemaccess/moduleitem', this.accessmoduleitemForm)
        .then(() => {
          this.$message({
            message: 'New Access Module Item Added',
            type: 'success'
          })
          this.getAccessModuleItem()
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    editAccessModuleItem (row) {
      this.editAccessModuleItemDialog = true
      this.editaccessmoduleitemForm.id = row.id
      this.editaccessmoduleitemForm.name = row.name
    },
    updateAccessModuleItem () {
      this.editAccessModuleItemDialog = false
      this.editaccessmoduleitemForm.sam_id = this.sam_id
      this.editaccessmoduleitemForm.vue_object_name = this.editaccessmoduleitemForm.name
      this.editaccessmoduleitemForm.vue_parent_name = this.access_module.name
      this.$http
        .post('sysadmin.systemaccess/moduleitem-update', this.editaccessmoduleitemForm)
        .then(() => {
          this.$message({
            message: 'Access Module Item Updated',
            type: 'success'
          })
          this.getAccessModuleItem()
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    margin-right: 1rem;
  }
</style>
